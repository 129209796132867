@import 'src/scss/theme';

.container {
    position: relative;
    padding-bottom: 20px;
}

.errorMessage {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--validation-error-color);
    font-size: var(--validation-error-size);
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}
