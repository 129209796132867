@import 'scss/variables';
@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/utilities/align';
@import '~bootstrap/scss/utilities/background';
@import '~bootstrap/scss/utilities/borders';
@import '~bootstrap/scss/utilities/flex';
@import '~bootstrap/scss/utilities/float';
@import '~bootstrap/scss/utilities/sizing';
@import '~bootstrap/scss/utilities/text';
@import '~bootstrap/scss/utilities/spacing';

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
