.message {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headline {
    text-align: center;
}

.subHeadline {
    padding-top: 20px;
}
